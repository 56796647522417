@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/themes/material.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~webdatarocks/webdatarocks.min.css';
.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.3333333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(/assets/app/media/img/flags/4x3/ad.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(/assets/app/media/img/flags/4x3/ae.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(/assets/app/media/img/flags/4x3/af.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(/assets/app/media/img/flags/4x3/ag.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(/assets/app/media/img/flags/4x3/ai.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(/assets/app/media/img/flags/4x3/al.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/al.svg);
}

.flag-icon-am {
  background-image: url(/assets/app/media/img/flags/4x3/am.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(/assets/app/media/img/flags/4x3/ao.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(/assets/app/media/img/flags/4x3/aq.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(/assets/app/media/img/flags/4x3/ar.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(/assets/app/media/img/flags/4x3/as.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/as.svg);
}

.flag-icon-at {
  background-image: url(/assets/app/media/img/flags/4x3/at.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/at.svg);
}

.flag-icon-au {
  background-image: url(/assets/app/media/img/flags/4x3/au.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(/assets/app/media/img/flags/4x3/aw.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(/assets/app/media/img/flags/4x3/ax.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(/assets/app/media/img/flags/4x3/az.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(/assets/app/media/img/flags/4x3/ba.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(/assets/app/media/img/flags/4x3/bb.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(/assets/app/media/img/flags/4x3/bd.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(/assets/app/media/img/flags/4x3/be.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(/assets/app/media/img/flags/4x3/bf.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(/assets/app/media/img/flags/4x3/bg.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(/assets/app/media/img/flags/4x3/bh.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(/assets/app/media/img/flags/4x3/bi.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(/assets/app/media/img/flags/4x3/bj.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(/assets/app/media/img/flags/4x3/bl.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(/assets/app/media/img/flags/4x3/bm.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(/assets/app/media/img/flags/4x3/bn.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(/assets/app/media/img/flags/4x3/bo.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(/assets/app/media/img/flags/4x3/bq.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(/assets/app/media/img/flags/4x3/br.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(/assets/app/media/img/flags/4x3/bs.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(/assets/app/media/img/flags/4x3/bt.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(/assets/app/media/img/flags/4x3/bv.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(/assets/app/media/img/flags/4x3/bw.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(/assets/app/media/img/flags/4x3/by.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(/assets/app/media/img/flags/4x3/bz.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(/assets/app/media/img/flags/4x3/ca.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(/assets/app/media/img/flags/4x3/cc.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(/assets/app/media/img/flags/4x3/cd.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(/assets/app/media/img/flags/4x3/cf.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(/assets/app/media/img/flags/4x3/cg.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(/assets/app/media/img/flags/4x3/ch.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(/assets/app/media/img/flags/4x3/ci.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(/assets/app/media/img/flags/4x3/ck.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(/assets/app/media/img/flags/4x3/cl.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(/assets/app/media/img/flags/4x3/cm.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(/assets/app/media/img/flags/4x3/cn.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(/assets/app/media/img/flags/4x3/co.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(/assets/app/media/img/flags/4x3/cr.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(/assets/app/media/img/flags/4x3/cu.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(/assets/app/media/img/flags/4x3/cv.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(/assets/app/media/img/flags/4x3/cw.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(/assets/app/media/img/flags/4x3/cx.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(/assets/app/media/img/flags/4x3/cy.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(/assets/app/media/img/flags/4x3/cz.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(/assets/app/media/img/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(/assets/app/media/img/flags/4x3/dj.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(/assets/app/media/img/flags/4x3/dk.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(/assets/app/media/img/flags/4x3/dm.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(/assets/app/media/img/flags/4x3/do.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(/assets/app/media/img/flags/4x3/dz.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(/assets/app/media/img/flags/4x3/ec.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(/assets/app/media/img/flags/4x3/ee.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(/assets/app/media/img/flags/4x3/eg.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(/assets/app/media/img/flags/4x3/eh.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(/assets/app/media/img/flags/4x3/er.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/er.svg);
}

.flag-icon-es {
  background-image: url(/assets/app/media/img/flags/4x3/es.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/es.svg);
}

.flag-icon-et {
  background-image: url(/assets/app/media/img/flags/4x3/et.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(/assets/app/media/img/flags/4x3/fi.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(/assets/app/media/img/flags/4x3/fj.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(/assets/app/media/img/flags/4x3/fk.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(/assets/app/media/img/flags/4x3/fm.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(/assets/app/media/img/flags/4x3/fo.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(/assets/app/media/img/flags/4x3/fr.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(/assets/app/media/img/flags/4x3/ga.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(/assets/app/media/img/flags/4x3/gb.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(/assets/app/media/img/flags/4x3/gd.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(/assets/app/media/img/flags/4x3/ge.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(/assets/app/media/img/flags/4x3/gf.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(/assets/app/media/img/flags/4x3/gg.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(/assets/app/media/img/flags/4x3/gh.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(/assets/app/media/img/flags/4x3/gi.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(/assets/app/media/img/flags/4x3/gl.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(/assets/app/media/img/flags/4x3/gm.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(/assets/app/media/img/flags/4x3/gn.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(/assets/app/media/img/flags/4x3/gp.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(/assets/app/media/img/flags/4x3/gq.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(/assets/app/media/img/flags/4x3/gr.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(/assets/app/media/img/flags/4x3/gs.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(/assets/app/media/img/flags/4x3/gt.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(/assets/app/media/img/flags/4x3/gu.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(/assets/app/media/img/flags/4x3/gw.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(/assets/app/media/img/flags/4x3/gy.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(/assets/app/media/img/flags/4x3/hk.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(/assets/app/media/img/flags/4x3/hm.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(/assets/app/media/img/flags/4x3/hn.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(/assets/app/media/img/flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(/assets/app/media/img/flags/4x3/ht.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(/assets/app/media/img/flags/4x3/hu.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(/assets/app/media/img/flags/4x3/id.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(/assets/app/media/img/flags/4x3/ie.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(/assets/app/media/img/flags/4x3/il.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/il.svg);
}

.flag-icon-im {
  background-image: url(/assets/app/media/img/flags/4x3/im.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/im.svg);
}

.flag-icon-in {
  background-image: url(/assets/app/media/img/flags/4x3/in.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/in.svg);
}

.flag-icon-io {
  background-image: url(/assets/app/media/img/flags/4x3/io.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(/assets/app/media/img/flags/4x3/iq.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(/assets/app/media/img/flags/4x3/ir.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(/assets/app/media/img/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/is.svg);
}

.flag-icon-it {
  background-image: url(/assets/app/media/img/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/it.svg);
}

.flag-icon-je {
  background-image: url(/assets/app/media/img/flags/4x3/je.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(/assets/app/media/img/flags/4x3/jm.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(/assets/app/media/img/flags/4x3/jo.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(/assets/app/media/img/flags/4x3/jp.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(/assets/app/media/img/flags/4x3/ke.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(/assets/app/media/img/flags/4x3/kg.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(/assets/app/media/img/flags/4x3/kh.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(/assets/app/media/img/flags/4x3/ki.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(/assets/app/media/img/flags/4x3/km.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(/assets/app/media/img/flags/4x3/kn.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(/assets/app/media/img/flags/4x3/kp.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(/assets/app/media/img/flags/4x3/kr.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(/assets/app/media/img/flags/4x3/kw.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(/assets/app/media/img/flags/4x3/ky.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(/assets/app/media/img/flags/4x3/kz.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(/assets/app/media/img/flags/4x3/la.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(/assets/app/media/img/flags/4x3/lb.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(/assets/app/media/img/flags/4x3/lc.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(/assets/app/media/img/flags/4x3/li.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(/assets/app/media/img/flags/4x3/lk.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(/assets/app/media/img/flags/4x3/lr.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(/assets/app/media/img/flags/4x3/ls.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(/assets/app/media/img/flags/4x3/lt.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(/assets/app/media/img/flags/4x3/lu.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(/assets/app/media/img/flags/4x3/lv.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(/assets/app/media/img/flags/4x3/ly.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(/assets/app/media/img/flags/4x3/ma.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(/assets/app/media/img/flags/4x3/mc.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(/assets/app/media/img/flags/4x3/md.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/md.svg);
}

.flag-icon-me {
  background-image: url(/assets/app/media/img/flags/4x3/me.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(/assets/app/media/img/flags/4x3/mf.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(/assets/app/media/img/flags/4x3/mg.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(/assets/app/media/img/flags/4x3/mh.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(/assets/app/media/img/flags/4x3/mk.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(/assets/app/media/img/flags/4x3/ml.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(/assets/app/media/img/flags/4x3/mm.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(/assets/app/media/img/flags/4x3/mn.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(/assets/app/media/img/flags/4x3/mo.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(/assets/app/media/img/flags/4x3/mp.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(/assets/app/media/img/flags/4x3/mq.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(/assets/app/media/img/flags/4x3/mr.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(/assets/app/media/img/flags/4x3/ms.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(/assets/app/media/img/flags/4x3/mt.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(/assets/app/media/img/flags/4x3/mu.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(/assets/app/media/img/flags/4x3/mv.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(/assets/app/media/img/flags/4x3/mw.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(/assets/app/media/img/flags/4x3/mx.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(/assets/app/media/img/flags/4x3/my.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(/assets/app/media/img/flags/4x3/mz.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(/assets/app/media/img/flags/4x3/na.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(/assets/app/media/img/flags/4x3/nc.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(/assets/app/media/img/flags/4x3/ne.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(/assets/app/media/img/flags/4x3/nf.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(/assets/app/media/img/flags/4x3/ng.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(/assets/app/media/img/flags/4x3/ni.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(/assets/app/media/img/flags/4x3/nl.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(/assets/app/media/img/flags/4x3/no.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/no.svg);
}

.flag-icon-np {
  background-image: url(/assets/app/media/img/flags/4x3/np.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(/assets/app/media/img/flags/4x3/nr.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(/assets/app/media/img/flags/4x3/nu.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(/assets/app/media/img/flags/4x3/nz.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(/assets/app/media/img/flags/4x3/om.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(/assets/app/media/img/flags/4x3/pa.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(/assets/app/media/img/flags/4x3/pe.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(/assets/app/media/img/flags/4x3/pf.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(/assets/app/media/img/flags/4x3/pg.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(/assets/app/media/img/flags/4x3/ph.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(/assets/app/media/img/flags/4x3/pk.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(/assets/app/media/img/flags/4x3/pl.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(/assets/app/media/img/flags/4x3/pm.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(/assets/app/media/img/flags/4x3/pn.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(/assets/app/media/img/flags/4x3/pr.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(/assets/app/media/img/flags/4x3/ps.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(/assets/app/media/img/flags/4x3/pt.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(/assets/app/media/img/flags/4x3/pw.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(/assets/app/media/img/flags/4x3/py.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(/assets/app/media/img/flags/4x3/qa.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(/assets/app/media/img/flags/4x3/re.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(/assets/app/media/img/flags/4x3/ro.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(/assets/app/media/img/flags/4x3/rs.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(/assets/app/media/img/flags/4x3/ru.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(/assets/app/media/img/flags/4x3/rw.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(/assets/app/media/img/flags/4x3/sa.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(/assets/app/media/img/flags/4x3/sb.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(/assets/app/media/img/flags/4x3/sc.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(/assets/app/media/img/flags/4x3/sd.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(/assets/app/media/img/flags/4x3/se.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(/assets/app/media/img/flags/4x3/sg.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(/assets/app/media/img/flags/4x3/sh.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(/assets/app/media/img/flags/4x3/si.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(/assets/app/media/img/flags/4x3/sj.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(/assets/app/media/img/flags/4x3/sk.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(/assets/app/media/img/flags/4x3/sl.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(/assets/app/media/img/flags/4x3/sm.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(/assets/app/media/img/flags/4x3/sn.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(/assets/app/media/img/flags/4x3/so.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(/assets/app/media/img/flags/4x3/sr.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(/assets/app/media/img/flags/4x3/ss.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(/assets/app/media/img/flags/4x3/st.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(/assets/app/media/img/flags/4x3/sv.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(/assets/app/media/img/flags/4x3/sx.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(/assets/app/media/img/flags/4x3/sy.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(/assets/app/media/img/flags/4x3/sz.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(/assets/app/media/img/flags/4x3/tc.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(/assets/app/media/img/flags/4x3/td.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(/assets/app/media/img/flags/4x3/tf.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(/assets/app/media/img/flags/4x3/tg.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(/assets/app/media/img/flags/4x3/th.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(/assets/app/media/img/flags/4x3/tj.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(/assets/app/media/img/flags/4x3/tk.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(/assets/app/media/img/flags/4x3/tl.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(/assets/app/media/img/flags/4x3/tm.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(/assets/app/media/img/flags/4x3/tn.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(/assets/app/media/img/flags/4x3/to.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(/assets/app/media/img/flags/4x3/tr.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(/assets/app/media/img/flags/4x3/tt.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(/assets/app/media/img/flags/4x3/tv.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(/assets/app/media/img/flags/4x3/tw.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(/assets/app/media/img/flags/4x3/tz.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(/assets/app/media/img/flags/4x3/ua.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(/assets/app/media/img/flags/4x3/ug.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(/assets/app/media/img/flags/4x3/um.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/um.svg);
}

.flag-icon-us {
  background-image: url(/assets/app/media/img/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(/assets/app/media/img/flags/4x3/uy.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(/assets/app/media/img/flags/4x3/uz.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(/assets/app/media/img/flags/4x3/va.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(/assets/app/media/img/flags/4x3/vc.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(/assets/app/media/img/flags/4x3/ve.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(/assets/app/media/img/flags/4x3/vg.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(/assets/app/media/img/flags/4x3/vi.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(/assets/app/media/img/flags/4x3/vn.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(/assets/app/media/img/flags/4x3/vu.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(/assets/app/media/img/flags/4x3/wf.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(/assets/app/media/img/flags/4x3/ws.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(/assets/app/media/img/flags/4x3/ye.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(/assets/app/media/img/flags/4x3/yt.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(/assets/app/media/img/flags/4x3/za.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(/assets/app/media/img/flags/4x3/zm.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(/assets/app/media/img/flags/4x3/zw.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/zw.svg);
}

.flag-icon-es-ca {
  background-image: url(/assets/app/media/img/flags/4x3/es-ca.svg);
}
.flag-icon-es-ca.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/es-ca.svg);
}

.flag-icon-es-ga {
  background-image: url(/assets/app/media/img/flags/4x3/es-ga.svg);
}
.flag-icon-es-ga.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/es-ga.svg);
}

.flag-icon-eu {
  background-image: url(/assets/app/media/img/flags/4x3/eu.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(/assets/app/media/img/flags/4x3/gb-eng.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(/assets/app/media/img/flags/4x3/gb-nir.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(/assets/app/media/img/flags/4x3/gb-sct.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(/assets/app/media/img/flags/4x3/gb-wls.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(/assets/app/media/img/flags/4x3/un.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/un.svg);
}

.flag-icon-xk {
  background-image: url(/assets/app/media/img/flags/4x3/xk.svg);
}
.flag-icon-xk.flag-icon-squared {
  background-image: url(/assets/app/media/img/flags/1x1/xk.svg);
}

:root {
  --defaultIframePrintHeight: 5000px;
}

body {
  background-color: #eaedef;
}

@media print {
  body.modal-open > *:not(ngb-modal-window) {
    display: none !important;
  }

  header.m-header {
    display: none !important;
  }

  .m-grid.m-page__container.m-body {
    padding-top: 15px !important;
  }
}
.fc-day-grid-container,
.fc-scroller .fc-time-grid-container {
  overflow-x: hidden;
}

.mce-notification,
.mce-branding {
  display: none !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .mf-action-column {
  padding-top: 6px;
  padding-bottom: 0px;
}

.ngx-datatable.material {
  box-shadow: none;
}

.ngx-datatable .datatable-body-row.active .datatable-row-group {
  background-color: #dad8ee !important;
}

.modal-lg {
  max-width: 90%;
}

#cdk-overlay-0,
.owl-dt-container,
.cdk-overlay-container {
  z-index: 99999 !important;
}

.m-portlet__body .btn {
  white-space: normal;
}

.mf-content {
  padding: 10px;
  background-color: white;
}

.m-portlet .m-portlet__head {
  height: 3rem;
  padding: 0 1rem;
}
.m-portlet .m-portlet__head .m-portlet__head-caption .m-portlet__head-title .m-portlet__head-text {
  font-family: Poppins, sans-serif;
  font-size: 1rem;
}
.m-portlet .m-portlet__body {
  padding: 1rem;
}

.ngx-datatable.material.mf-custom-pagination .datatable-footer {
  border: none;
  border-top: none;
}

.mfa-row-no-margin {
  margin: 0px;
}

.mf-transparent-popup .modal-content {
  background-color: transparent;
  background: none;
  box-shadow: none;
}
.mf-transparent-popup .modal-footer {
  border: none;
}

.m-portlet {
  border-radius: 5px;
  margin-bottom: 20px;
}
.m-portlet .m-portlet__head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #999999;
}
.m-portlet .m-portlet__head .m-portlet__head-text {
  color: white;
}

.mfa-popup.mfa-popup__small .modal-dialog {
  height: auto;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.mfa-popup.mfa-popup-white .modal-content .modal-body {
  background-color: white;
}
.mfa-popup .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 30px;
  padding-bottom: 50px;
  margin: 0px;
  margin-right: 30px;
}
.mfa-popup .modal-content {
  height: 99%;
  background: transparent;
  box-shadow: none;
}
.mfa-popup .modal-content .modal-body {
  background-color: #f8f9fa;
  box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
  border-radius: 4px;
}
.mfa-popup .modal-content .modal-footer {
  border: none;
  padding-bottom: 0px;
}
.mfa-popup .modal-content .modal-header {
  color: white;
  text-align: center;
  border-bottom: none;
  padding-top: 0px;
}
.mfa-popup .modal-content .modal-header .modal-title {
  color: white;
  text-align: center;
  margin: auto;
  font-size: 25px;
}

.mfa-custom-type-ahead .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

body > ngb-modal-window > div > div > div.modal-body > app-contractors > div > div:nth-child(2) > div.m-portlet__body > div > ngx-datatable > div > datatable-body > datatable-selection > datatable-scroller {
  width: 100% !important;
}

.mfa-flex-row {
  border-bottom: 1px dashed #C4C5D5;
}

.mfa-flex-row {
  padding: 10px;
}

.mfa-portlet-mid-title .m-portlet__body {
  padding: 0px;
}
.mfa-portlet-mid-title .mfa-portlet-mid-title__padding {
  padding: 14px;
}

.mfa-portlet-mid-title__title {
  background-color: #e2e4eb;
  font-weight: 500;
  padding: 5px 14px;
}

.mfa-portlet-mid-title__text {
  line-height: 30px;
}

.row.mfa-custom-row .col-lg-3,
.row.mfa-custom-row .col-xl-3,
.row.mfa-custom-row .col-lg-6,
.row.mfa-custom-row .col-xl-6,
.row.mfa-custom-row .col-lg-9,
.row.mfa-custom-row .col-xl-9 {
  padding-left: 10px;
  padding-right: 10px;
}

.mfa-dashed-h {
  border-bottom: 1px dashed #C4C5D5;
}

.mfa-accordion .card-header {
  padding: 0px;
}
.mfa-accordion .btn-link {
  text-decoration: none;
}
.mfa-accordion .card {
  margin-bottom: 5px;
}

.mfa-accordionPriceList .card-header {
  padding: 0px;
}
.mfa-accordionPriceList .card-header button {
  width: 100%;
}
.mfa-accordionPriceList .btn-link {
  text-decoration: none;
}
.mfa-accordionPriceList .card {
  margin-bottom: 5px;
}

.m-timeline-2:before {
  left: 86px;
}

.m-timeline-2 .m-timeline-2__items .m-timeline-2__item .m-timeline-2__item-cricle {
  left: 75px;
}

.m-timeline-2 .m-timeline-2__items .m-timeline-2__item .m-timeline-2__item-text {
  padding-left: 77px;
}

.mfa-poup-content-fix-screen-size {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 160px);
}

.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  cursor: pointer;
  background-color: #ffeece;
}

.mf-pointer:hover {
  cursor: pointer;
}

.m-badge.mf-badge--medium {
  line-height: 10px;
  min-height: 10px;
  min-width: 10px;
  height: 10px;
  width: 10px;
}

.mf-opacit-05 {
  opacity: 0.5;
}

.app-coa-login {
  height: 100%;
}

.mfa-badge--outline.m-badge {
  background: none;
  border-width: 3px;
  border-style: solid;
}
.mfa-badge--outline.m-badge.m-badge--danger {
  border-color: #FB5058;
}
.mfa-badge--outline.m-badge.m-badge--warning {
  border-color: #FDB737;
}
.mfa-badge--outline.m-badge.m-badge--success {
  border-color: #3DBEA3;
}
.mfa-badge--outline.m-badge.m-badge--accent {
  border-color: #21C5DA;
}

.file-upload {
  outline-offset: 0 !important;
  padding: 1rem !important;
  outline: #ebedf2 dashed 1px !important;
}
.file-upload.list-visible .upload-input {
  position: relative;
  top: 1rem !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin-left: 0 !important;
}
.file-upload .file-info {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  padding: 5px 0 5px 0;
}
.file-upload .file-info:last-child {
  border: none;
  padding-bottom: 0px;
}
.file-upload .file-info a {
  cursor: pointer;
}

datatable-scroller {
  width: 100% !important;
}

#notificationMarkAsRead {
  cursor: pointer;
  padding-left: 10px;
}

.mf-text-icon {
  color: black;
  font-size: 11px;
}

.m-page-loader.m-page-loader--block {
  background: rgba(255, 255, 255, 0.6);
  z-index: 9000;
}

.ngx-datatable .datatable-body .progress-linear .container {
  background-color: #ffeece !important;
}
.ngx-datatable .datatable-body .progress-linear .container .bar {
  background-color: #FDB737 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.mf-h-input {
  position: absolute;
  left: -2000px;
}

.la.la-2x {
  font-size: 2em !important;
}
.la.la-3x {
  font-size: 3em !important;
}
.la.la-4x {
  font-size: 4em !important;
}
.la.la-5x {
  font-size: 5em !important;
}

.mf-dashed-h {
  border-bottom: 1px dashed #C4C5D5;
}

.mf-dashed-h__light {
  border-bottom: 1px dashed #ebedf2;
}

/* Intercom custom button color */
.intercom-launcher-frame {
  background: linear-gradient(90deg, #fdb737 0%, rgba(253, 183, 55, 0.7) 100%) !important;
}

.m-btn--icon > span > span,
.btn {
  font-weight: 600;
}

.telephony {
  background-color: #fcfcfc;
}
.telephony iframe {
  border: none;
}
.telephony .mf-phone-wrapper {
  height: 666px;
  width: 376px;
}
.telephony .telephony-large-modal {
  background-color: #fcfcfc;
  position: fixed;
  top: 10%;
  right: 10%;
  margin: 0 auto 0 auto;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5), 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 0px 10px 5px 10px;
}
.telephony .telephony-large-modal:hover {
  cursor: grab;
}

.mf-call-number__icon,
.mf-call-number {
  background-color: #dad8ee;
}
.mf-call-number__icon:hover,
.mf-call-number:hover {
  cursor: pointer;
  background-color: #FDB737;
  color: white !important;
}

.mf-call-number {
  border-radius: 10px;
  padding-left: 10px;
  position: relative;
  left: -10px;
}

.mf-call-number__icon {
  border-radius: 10px;
  position: relative;
  padding: 5px;
  left: -5px;
}

button:focus {
  outline-color: #fdb737;
}